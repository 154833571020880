.formControl {
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    cursor: pointer;
}

.customCheckbox {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    box-shadow: rgba(149, 157, 165, 0.5) 0 8px 24px;;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
}

.customCheckbox::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background-color: white;
  } 

.customCheckbox:checked {
    background-color: var(--clr-purple);
}

.customCheckbox:checked::before {
    transform: scale(1);
}
