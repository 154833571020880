.leftHeaderContainer{
    flex: 1;
    display: flex;
    font-size: 0.5rem;
    text-align: left;
    align-items: center;
}

.learnHowContainer {
    margin-left: 0.2rem;
    color: black;
}

.iButton {
    font-family: 'Courgette', cursive;
    box-shadow: rgba(250,196,53,0.75) 0 5px 15px;
    margin: 0 0.4rem;
    border: none;
    outline: none;
    padding: 4px 6px;
    background-color: transparent;
    border-radius: 0.3rem 0 0.3rem 0;
    letter-spacing: 0.02rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.iButton:hover {
    transform: scale(1.1);
}