.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    position: relative;
}

.wrapper.alignItemsFlexStart {
    align-items: flex-start;
}

.wrapper.flexRow {
    align-items: flex-start;
    flex-direction: row;
}

.wrapper.onlyFlexRow {
    flex-direction: row;
}

.wrapper.justifyContentAlignItemsFlexStart {
    justify-content: flex-start;
    align-items: flex-start;
}