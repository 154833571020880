.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.subContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}