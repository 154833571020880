.listItemsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

@media (max-width: 768px) {
    .listItemsContainer {
        align-items:center;
    }
}
