.sustainableVenuesContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border-radius: 5px;
    position: absolute;
    top: 55px;
    z-index: 1405;
    min-width: 300px;
    width: 360px;
    right: 10%;

}

.sustainableVenuesContainer.list {
    top: 270px;
    left: 45%;
}

.title {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--clr-gray-800);
}

.sustainableVenuesUpperContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.8rem;
}

.sustainableVenuesItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sustainableVenuesBottomContainer {
    display: flex;
    justify-content: flex-end;
  
}
.sustainableVenuesBottomContainer > *{
    margin: 0;
}

@media (max-width: 645px) {
    .sustainableVenuesContainer {
        left: -150px;
    }
}
