.stayFormTextContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.stayFormText {
    color: #a0abb5;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 1.1rem;
}

.stayFormTextContainer.purple * {
    color: var(--clr-purple);
}

.stayFormText.purple {
    color: var(--clr-purple);
    font-weight: 700;
}

.listContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 5vw;
}

.listContainer > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}


@media (max-width: 768px) {
    .stayFormText {
        font-size: 1rem;
    }

    .listContainer {
        margin-left: 0;
    }
}
