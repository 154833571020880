.container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 20px 0;    
    border-bottom: 2px solid var(--clr-gray-200);
}

.container > div{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    color: var(--clr-purple);
    text-transform: capitalize;
    letter-spacing: 1px;

}

.titleContainer {
    font-size: 1rem;
    width: 220px;
}

.rightContainer {
width: 100%;
}


@media (max-width: 1200px) {
    .container {
        flex-direction: column;
    }
}