.helpContainer{
    margin: 1rem 0 7rem;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .helpContainer{
        margin: 1rem 0;
    }
}
