.grid {
    display: grid;
    /*grid-template-columns: repeat(3, 1fr);*/
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}