.dropdownContent {
    background-color: white;
    border-radius: 0.4rem;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out, box-shadow 0.3s ease-in-out;
    position: absolute;
    top: 70px;
    border: none;
    z-index: 2;
  }
  
  .dropdownContentOpen {
    composes: dropdownContent;
    max-height: 300px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%; 
  }
  
  .dropdownItem {
    margin: 5px 0;
    padding: 10px;
    cursor: pointer;
    background-color: transparent;
    font-size: 1rem;
    letter-spacing: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
  }

  .dropdownItem:hover {
    background-color: var(--clr-gray-100);
  }