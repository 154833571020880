.items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
}

.videos {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 1.5rem;
}

@media (max-width: 700px) {

    .items{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

}