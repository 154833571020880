.title {
    font-size: 1.2rem;
    font-weight: 500;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-start;
    column-gap: 10rem;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 3rem 0 1rem;
    row-gap: 1rem;
}

.buttonsSubContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
}

.gridItem{
    position: relative;
    width: 100%;
    padding-bottom: 75%; 
    border-radius: 1rem 0 1rem 0;
}

.gridItem img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem 0 1rem 0;
}

.gridItem:hover .youtubeIcon {
    display: block !important;
  }

.youtubeIcon {
    display: none !important;
}

.view-more-btn-pp {
    color: rgb(42, 42, 42);
    font-size: 1rem;
    border: 1px solid gray;
    margin: 1rem 0;
    font-weight: 600;
}

.ytWrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.youtube {
    width: 60%;
    height: 500px;
    border-radius: 1rem 0 1rem 0;
    cursor: pointer;
    object-fit: contain;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.youtube  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem 0 1rem 0;
}

.videosContainer {
    display: flex;
    width: 100%;
}

@media (max-width: 1000px) {
    .youtube {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .youtube {
        width: 90%;
    }
    .grid {
        grid-template-columns: repeat(2, 1fr);
      }
}

@media screen and (max-width: 500px) {
    .youtube {
        width: 100%;
    }
    .grid {
      grid-template-columns: repeat(1, 1fr);
    }
}