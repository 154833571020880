.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
    margin: 0 auto;

}

.imageContainer {
    max-width: 400px;
    height: auto;
    position: relative;
    border-radius: 1rem 0 1rem 0;

}

.title {
    font-size: 1.3rem;
    font-weight: 500;
}

.imageContainer img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 1rem 0 1rem 0;

}

.openGallery {
    background-color: rgba(25, 25, 50, 0.7);
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: 1rem 0 1rem 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.openGallery div {
    font-size: 2rem;
    color: white;

}

@media (max-width: 1124px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: repeat(1, 1fr);
    }
}