.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px;
}

.testimonialImage {
    max-width: 100px;
    height: auto;
    border-radius: 50%;
}

.name {
    font-style: italic;
}

.stars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.review {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}