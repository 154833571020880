.container {
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}