.toggleCardButton {
    background-color: transparent;
    border: 1px solid var(--clr-gray-250);
    cursor: pointer;
    padding: 8px 20px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
}

.toggleCardButton:not(:last-child) {
    border-right: none;
}

.toggleCardButton:last-child {
    border-radius: 0 0 0.3rem 0;
}

.toggleCardButton:first-child {
    border-radius: 0.3rem 0 0 0;
}

.toggleCardButton:hover {
    background-color: var(--clr-gray-200);
}

.clicked {
    background-color: var(--clr-gray-200);
    font-weight: bold;
}

@media (max-width: 600px) {
    .toggleCardButton:nth-child(2) {
        border-right: 1px solid var(--clr-gray-250);
    }
}

@media (max-width: 400px) {
    .toggleCardButton:not(:last-child) {
        border-right: 1px solid var(--clr-gray-250);
    }
}


