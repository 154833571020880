.whenDropdownContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 300px;
    padding: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    position: absolute;
    z-index: 500;
    background-color: white;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}