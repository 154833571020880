.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title {
    color: var(--clr-purple);
    font-weight: bold;
    letter-spacing: 0;
}