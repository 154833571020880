.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.title{
    color: black;
    font-weight: 500;
    letter-spacing: 2px;
}

.buttonsContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.buttonsContainer > div {
    cursor: pointer;
}

.viewButton {
    color: var(--clr-pink);
}

.removeButton{
    color: var(--clr-gray-280);
}


