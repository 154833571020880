.author {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: var(--clr-gray-500);
    letter-spacing: 3px;
}

.title {
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
    font-weight: 600;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
}

.title::before,
.title::after {
    content: "";
    position: absolute;
    top: 55%;
    width: 40%;
    height: 3px;
    background-color: black;
}

.title::before {
    right: 100%;
    margin-right: 1rem;
    transform: translateY(-50%);
}

.title::after {
    left: 100%;
    margin-left: 1rem;
    transform: translateY(-50%);
}



@media (max-width: 1420px) {
    .title::before,
    .title::after {
        width: 30%;
    }
    .mainBlogContainer {
        width: 80%;
    }
}

@media (max-width: 1024px) {
    .title::before,
    .title::after {
        width: 20%;
    }
}

@media (max-width: 900px) {
    .title {
        font-size: 2.5rem;
    }
    .title::before,
    .title::after {
        width: 40%;
    }

    .title::before {
        margin-right: 0.5rem;
    }

    .title::after {
        margin-left: 0.5rem;
    }
}

@media (max-width: 600px) {
    .title::before,
    .title::after {
        width: 0;
    }

    .author {
        flex-direction: column;
    }
}
