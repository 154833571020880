
.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
}

.titleSubContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 1rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
}
