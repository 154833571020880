.backToTop {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: fit-content;
    z-index: 1201;
}

.backToTopButton {
    outline: none;
    background-color: #fff;
    border: 1px solid var(--clr-pink);
    color:black;
    padding: 8px 24px;
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
}