* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
   text-decoration: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

