@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeOut {
    animation: fadeOut 0.5s ease forwards;
}


.shortlistedContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;*/
    padding: 2rem 1rem;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2000;
    background-color: white;
}

.shortlistedTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shortlistedTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.shortlistedTitleContainer  > * {
    color: var(--clr-pink);
    font-size: calc(1rem + 1vw);
    font-weight: 500;
}

.venuesCardsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    /*height: 100%;*/
}

.maxMsg {
    color: var(--clr-pink);
}

.shortlistedBottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 0;
    margin-top: 1rem ;
    padding-bottom: 1rem;
}


.noShortlistVenues {

}
/*.scrollableList {*/
/*    height: 200px;*/
/*    overflow: scroll;*/
/*}*/