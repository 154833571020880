.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 1rem;
    align-items: end;
    justify-content: center;
}

@media (max-width: 900px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 500px) {
    .grid {
        grid-template-columns: repeat(1, 1fr);
    }
}