.imageSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 1rem;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex.backTo {
    color: var(--clr-purple);
    font-size: 0.7rem;
}

.flex.guestRoom {
    column-gap: 1rem;
}

.image {
    width: 100%;
    border-radius: 1rem 0 1rem 0;
}

.iconsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.iconsContainer div {
    flex: 1;
}