.listItemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.rightContainer > div {
    flex: 1;
}

.image {
    width: 30%;
    border-radius: 1rem 0 1rem 0;

}

.shortlistedButton {
    width: 200px;
    position: relative;
    min-height: 45px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buttonsContainerVariant {
    display: flex;
    gap: 8px;
}

.starRatingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.venueButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.venueInfo {
    font-size: 0.9rem;
    border: 1px solid var(--clr-gray-280);
    border-radius: 0.5rem 0 0.5rem 0;
    padding: 0.6rem 0.6rem;
    text-align: center;
}

@media (max-width: 850px) {
    .image {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    .listItemContainer {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2rem;
    }

    .rightContainer {
        width: 100%;
    }
}
