.galleryPickerContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    gap: calc(1vw + 0.5rem);
    justify-content: center;
    align-items: center;
    position: relative;
}

.galleryContainer {
    display: flex;
    flex-direction: column;
    gap: calc(1vw + 0.1rem);
    justify-content: center;
    align-items: center;
}

.subcontainer {
    position: relative;
}

.subcontainer > img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.galleryImage {
    max-width: 300px;
    min-width: 100px;
    height: auto;
    border-radius: 0.5rem 0 0.5rem 0;
}

.subcontainer > button {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background-color: rgba(255,255,255,0.5);
    border: none;
    outline: none;
    padding: 0.5rem;
    border-radius: 0.5rem 0 0.5rem 0;
    cursor: pointer;
}

.text {
    color: white;

}