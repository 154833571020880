.container {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: rgba(0,0,0,0.8);
    border-radius: 1rem 0 1rem 0;
}

.text {
    color: white;

}

.btnWA{
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    padding: 7px 12px;
    cursor: pointer;
    z-index: 1000;
}