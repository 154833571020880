.button {
    border: 1px solid var(--clr-gray-200);
    outline: none;
    padding: 10px 15px;
    color: white;
    border-radius: 0.7rem 0 0.7rem 0;
    letter-spacing: 0.02rem;
    cursor: pointer;
    background-color: var(--clr-purple);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;

}

.button:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}
.button:active {
    background-color: #656fac;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); /* Creates an inset shadow to give a "pressed" effect */
    transform: translateY(2px); /* Slightly shifts the button down to simulate pressing */
}



.button.inverse {
    background-color: white;
    color: var(--clr-purple);
}

.button.outlined {
    background: transparent;
}

.button.outlined.purple {
    color: var(--clr-purple);
    border: 1px solid var(--clr-purple);
}

.button.outlined.gray {
    color: var(--clr-gray-800);
    border: 1px solid var(--clr-gray-280);
}

.button.pink {
    background-color: var(--clr-pink);
    color: white;
    border: 1px solid var(--clr-pink);
}

.button.outlined.pink {
    color: var(--clr-pink);
    border: 1px solid var(--clr-pink);
    background-color: transparent;
}

.button.white {
    position: absolute;
    bottom: 2rem;
    right: 1rem;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.1rem;
    color: var(--clr-gray-800);
    z-index: 4;
}

.button:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.button.pink.home {
    height: 56px;
    margin-top: 7px;
}