.miceLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
}

.circle.pink {
    background-color: var(--clr-pink);
}

.circle.yellow {
    background-color: var(--clr-yellow);
}

.letterM {
    font-size: 3rem;
    font-weight: bold;
    color: black;
}