.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.leftContainer {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.cardImage {
    border-radius: 0.3rem 0 0.3rem 0;
    width: 110px;
    height: 70px;
    object-fit: cover;
}

.emptyCardContainer {
    border-radius: 0.3rem 0 0.3rem 0;
    width: 110px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--clr-gray-280);
    cursor: pointer;
}
