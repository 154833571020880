.closedContainer{
    display: none;
}

.openContainer {
    height: 100vh;
    width: 100vw;
    background-color: rgba(73,85,157,0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 210;
    overflow: hidden;
    overflow-y: auto;
    
}

.openContent {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem 0 1rem 0; 
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    width: 55%;
}

.closedContent {
    width: 0;
    height: 0;
    display: none;
    transition: all 0.5s ease-in-out;
}

.upperContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--clr-purple);
    padding: 0.7rem;
    border-radius: 1rem 0 0 0;
}

.upperContainer > span {
    color: white;
    font-size: 0.8rem;
}

.bottomContainer{
    background-color: white;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 1rem 0;
    position: relative;
    height: 100%;
    gap: 1rem;
}

.iconsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.items{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
}

.videos {
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 1024px) {
    .openContent {
        width: 80%;
    }
}

@media (max-width: 700px) {
    .openContent {
        width: 100%;
        height: 100%;

        margin: auto;
        padding: 0;
        position: fixed;

    }
    .iconsContainer {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .items{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .bottomContainer{
        background-color: white;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0 0 1rem 0;
        position: relative;
        gap: 1rem;
    }
}