.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.container.col {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.label {
    color: var(--clr-purple);
    font-weight: bold;
    font-size: 1.2rem;
    text-align: left;
}