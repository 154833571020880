.listItemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: calc(1rem + 0.9vw);
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    padding-right: 12px;
    border-radius: 1rem 0 1rem 0;
    transition: box-shadow 0.3s ease;
}

.listItemContainer:hover {
    box-shadow: rgba(100, 100, 111, 0.4) 0 10px 40px 0;
}

.listItemContainer > * {
    flex: 1;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}

.rightContainer > div {
    flex: 1;
}

.image {
    width: 30%;
    border-radius: 1rem 0 1rem 0;
}

.shortlistedButton {
    width: 200px;
    position: relative;
    min-height: 45px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buttonsContainerVariant {
    display: flex;
    gap: 8px;
}

.starRatingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.venueButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.venueInfo {
    font-size: 0.8rem;
    color: var(--clr-purple);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
}

.sliderImage {
    max-width: 100%;
    object-fit: cover;
    object-position:center;
    height: 200px;
    left: 0;
    top: 0;
    cursor: pointer;
    border-radius: 1rem 0 1rem 0;

}

.line {
    height: 12px;
    background-color: var(--clr-gray-280);
    width: 1px;
}

@media (max-width: 1200px) {
    .image {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .listItemContainer {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-right: 0;
        padding-bottom: 12px;
    }

    .rightContainer {
        width: 100%;
        padding: calc(8px + 0.5vw);
    }
}
