.conciergeEnquiryFormContainer {
    width: 80%;
    margin: 3rem auto;
    padding: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media (max-width: 768px) {
    .conciergeEnquiryFormContainer {
        width: 100%;
        margin: 2rem auto;
    }

    .formContainer {
        gap: 1rem;
    }
}
