.sectionWrapper {
    display: flex;
    width: 100%;
    /*margin: 0 auto;*/
    row-gap: 3rem;
}

.sectionWrapper.leftSection {
    flex-basis: 70%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.sectionWrapper.rightSection {
    flex-basis: 30%;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.text {
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 1rem 0 0 0;
    padding: 20px ;
}

@media(max-width: 1100px) {
    .sectionWrapper {
        flex-direction: column;
    }
}