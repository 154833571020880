.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 250px;
    /* margin-left: -2rem; */
}
.montenegro {
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 0;
    
}

.miceLetters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.circle.yellow {
    background-color: var(--clr-yellow);
}

.circle.pink {
    background-color: var(--clr-pink);
}

.circle.purple {
    background-color: var(--clr-purple);
}


.miceSpan {
    font-size: 4rem;
    font-weight: 700;
}

