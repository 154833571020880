.navbarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    width: 100vw;
    /*border-bottom: 1px solid var(--clr-gray-200);*/
}

.leftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.navbarContainer.full {
    width: 100vw;
    justify-content: space-around;
}

.navbarItem{
    letter-spacing: 0.02rem;
    color: var(--clr-gray-500);
    cursor: pointer;
}

.downArrow{
    vertical-align: bottom;
    color: var(--clr-gray-800);
}

.navbarButtonsContainer{
    display: flex;
    gap: 10px;
}

.navbar-item.navbarItemLogo {
    display: flex;
    column-gap: 1rem;
}

.paper {
    min-width: 200px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: 'Montserrat', sans-serif;
}

.mobileNavVisibleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(1rem + 0.3vw);
}