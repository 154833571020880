.dropdownContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 3rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    position: absolute;
    z-index: 1000;
    background-color: white;
    border-radius: 5px;
    top: 70px;
    width: 60vw;
    left: -30vw;
    gap: 10px;
}

.dropdownContainer.closed {
    display: none;
}


.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1rem 0 ;
    gap: 0.5rem;
}

@media(max-width: 1450px) {
    .dropdownContainer {
        max-width: 800px;
        left: -25vw;
    }
}

@media(max-width: 1180px) {
    .dropdownContainer {
        max-width: 600px;
        left: -30vw;
    }
}

@media(max-width: 1080px) {
    .dropdownContainer {
        min-width: 500px;
        max-width: 600px;
        left: -35vw;
    }
}

@media(max-width: 766px) {
    .dropdownContainer {
        max-width: 800px;
        width: 80vw;
        min-width: 300px;
        padding: 1rem 1rem 1rem 2rem;
        left: 0;
    }


    .buttonsContainer    {
        justify-content: flex-start;
    }
}

@media(max-width: 600px) {
    .dropdownContainer {
        max-width: 500px;
        min-width: 400px;
        padding: 1rem 1rem 1rem 2rem;
    }
}

@media(max-width: 450px) {
    .dropdownContainer {
        min-width: 200px;
        width: 100%;
        padding: 1rem;
    }
}