.iconsContainer {
    position: absolute;
    z-index: 2;
    right: 0;
    display: flex;
    column-gap: 1rem;
    padding: 1rem;
}

.iconsImage{
    position: relative;
    object-fit: cover;
    height: 100%;
    width: 100%;
}