.eventFormContainer {
display: flex;
flex-direction: column;
padding: calc(20px + 2vw);
    width: 100%;
}

.title {
    color: var(--clr-purple);
    padding-bottom: 40px;
    border-bottom: 2px solid var(--clr-gray-200);
    font-size: 1.2rem;
}

.nextButton {
display: flex;
justify-content: end;
margin-top: 40px;
}

.eventDates {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.rooms {
    margin: 20px 0;
}

.buttons {
    display: flex;
    gap: 10px;
    justify-content: end;
}