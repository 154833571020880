.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.errmsg {
    color: red;
}

.offscreen {
display: none;
}