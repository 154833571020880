.container {
    display: flex;
    flex-wrap: wrap;
    /*max-width: 700px;*/
    gap: 0.8rem;
}

.container.list {
    max-width: max-content;
    row-gap: 1rem;
    column-gap: 5px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.numOfPeople {
    border: 1px solid var(--clr-gray-280);
    border-radius: 0.5rem 0 0.5rem 0;
    padding: 1.1rem 0.8rem;
    color: var(--clr-gray-500);
    cursor: pointer;
    position: relative;
}

.btn {
    min-width: 200px;
    max-width: 400px;
    background-color: var(--clr-purple);
    color: white;
    border-radius: 0.5rem 0 0.5rem 0;
    border-color: transparent;
    padding: 1rem 0;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.btn:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}
.btn:active {
    background-color: #656fac;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); /* Creates an inset shadow to give a "pressed" effect */
    transform: translateY(2px); /* Slightly shifts the button down to simulate pressing */
}



.btn.reverse {
    background-color: white;
    color: var(--clr-purple);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}