.filtersContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}

.filtersSubcontainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.filters {
    display: flex;
    column-gap: 0.5rem;
    width: 400px;
}

.filters div {
    flex: 1;
}

.cardsContainer {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

/* For tablets and smaller devices */
@media (max-width: 768px) {
    .filtersContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem; /* space between filters and button group */
    }

    .filters {
        width: 100%;
        flex-wrap: wrap; /* allows the filters to wrap onto the next line if there's not enough space */
        gap: 0.5rem; /* space between each filter */
    }
}

/* For mobile devices */
@media (max-width: 480px) {
    .filters {
        flex-direction: column;
        align-items: stretch; /* makes each filter take up the full width */
        gap: 0.5rem; /* space between each filter */
    }
}
