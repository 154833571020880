.slick-slider-container .slick-list {
    /*min-height: 300px;*/
    /*min-width: 200px;*/
    /*max-height: 500px;*/
    /*max-width: 100vw;*/
    width: 100%;
}

.slick-slider-container {
    width: 100%;
   overflow-x: hidden;
}
/* Styling for the overall slider container */
.slick-slider-container {
    width: 100%;
    overflow-x: hidden;
    padding: 20px 0;  /* Adding some vertical padding */
}

/* Styling for the list inside the slick slider container */
.slick-slider-container .slick-list {
    height: 450px;  /* Fixed height, adjust as necessary */
    width: 100%;
}

/* Ensuring each slide item is centered and consistent */
.slick-slider-container .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 5px;  /* adds a small margin to left and right of each slide */
}

/* Styling for the image container */
.image-container-div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    position: relative;
    object-fit: cover;
}
