.headerContainer{
    display: flex;
    width: 100%;
    margin: 5rem 0;
    justify-content: space-evenly;
}

.rightHeaderContainer {
    flex: 1;
}

@media(max-width: 900px) {
    .headerContainer {
        flex-direction: column-reverse;
        align-items: center;
        gap: 2rem;
        margin: 3rem 0;
    }
}

@media(max-width: 600px) {
    .headerContainer {
        margin: 2rem 0;
    }
}

@media(max-width: 400px) {
    .headerContainer {
        margin: 1rem 0;
    }
}