.mainSpan {
    font-weight: bold;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

@media (max-width: 600px) {
    .subContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}