.firstColumnCell {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    align-items: center;
    background-color: var(--clr-gray-200);
    gap: 10px;
    width: 100%;
    padding: 0;
}

.firstColumnCellImage{
    width: 40px;
    height: 50px;
    object-fit: contain;
    border-radius: 1rem;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.viewDetails {
    color: var(--clr-pink);
    font-weight: 500;
}