.customized-video-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.customized-video-gallery-container .custom-slide {
     height: 70vh;
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    row-gap: 3rem;
}

.customized-video-gallery-container .image-gallery {
    /*margin-top: 10rem;*/
    width: 70%;
    height: 100%;
    margin: 10vh 0;
}

.customized-video-gallery-container .arrow-container {
    width: 40px;
    height: 40px;
    background-color: rgb(62,67,73);
    z-index: 3000;
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.customized-video-gallery-container .image-gallery-icon.image-gallery-left-nav {
    color: white;
    background-color: rgba(128, 128, 128, 0.541);
    border-radius: 50%;
    font-size: 1rem;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.customized-video-gallery-container .image-gallery-svg {
    font-size: 1rem;
}

.customized-video-gallery-container .custom-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
  }

.customized-video-gallery-container .custom-left-arrow {
    left: 10px;
}

.customized-video-gallery-container .custom-right-arrow {
    right: 10px;
}

.customized-video-gallery-container .image-gallery-thumbnail.active,.customized-video-gallery-container .image-gallery-thumbnail:focus,.customized-video-gallery-container .image-gallery-thumbnail:hover{
    outline: none;
    border: 4px solid white !important;
    cursor: pointer;
}

.customized-video-gallery-container .image-gallery-thumbnail,.customized-video-gallery-container .image-gallery-thumbnail-inner,.customized-video-gallery-container .image-gallery-thumbnail-image {
    border-radius: 0.8rem 0 0.8rem 0;
}

.customized-video-gallery-container .custom-slide,.customized-video-gallery-container .custom-slide img {
    border-radius: 1rem 0 1rem 0;
}

.customized-video-gallery-container .react-gallery-title {
    font-size: 2rem;
    color: white;
}

.customized-video-gallery-container .react-gallery-subtitle {
    color: white;
    font-size: 1.2rem;
}

/*.customized-video-gallery-container .css-79ws1d-MuiModal-root > div:not(:first-child) {*/
/*    width: 70%;*/
/*}*/