.starRating {
    background-color: var(--clr-purple);
    border-radius: 0.4rem 0 0.4em 0;
    max-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 6px 10px;
    gap: 5px;
    font-size: 1rem;
    cursor: pointer;
}