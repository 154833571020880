.needHelpContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.grayBackgroundContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    background-color: var(--clr-gray-200);
    padding: 1rem 2rem;
    border-radius: 0.3rem 0 0.3rem 0;
}

.askAnExpertButton{
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 0.3rem 0 0.3rem 0;
    color: var(--clr-pink);
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
    font-weight: bold;
    padding: 1.25rem;
    cursor: pointer;
}

.needHelpSpan{
    color: var(--clr-pink);
    font-weight: bold;
}

.weProvideSpan{
    margin-left: 5px;
}
