.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1em;
}

.title {
    font-size: 3em;
    color: black;
    font-weight: 700;
    letter-spacing: 1px;
}

.title.purple {
    color: var(--clr-purple);
}

.subtitle {
    color: var(--clr-gray-500);
    font-size: 1rem;
    font-weight: 300;
}