.listMapContainer {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 2rem;

    min-height: 500px;
    width: 100%;
}

.listMapContainer:first-child {
    flex: 4;
}

.listMapContainer:nth-child(2) {
    flex: 3;
}

@media (max-width: 700px) {
    .listMapContainer {
        flex-direction: column;

    }
}

