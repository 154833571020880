.muiDateRangePicker {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;
}
.muiDateRangePicker > * {
    flex: 1;
}

@media (max-width: 500px) {
    .muiDateRangePicker {
        flex-direction: column;
    }
}

/*.muiDateRangePicker.column {*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/

/*}*/
