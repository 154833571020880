.shareWithContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.shareWithIconsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.text {
    color: var(--clr-purple);
    font-weight: bold;
}

.iconsLine {
    height: 50px;
    width: 1px;
    background-color: var(--clr-gray-280);
}