.popularLocations{
    background-color: var(--clr-gray-200);
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
}


.title{
    font-size: 1.5rem;
    font-weight: 600;
    width: 82vw;
    display: flex;
    margin-bottom: 2rem ;
}

.grid {
    width: 82vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5rem;
    justify-content: center;
    align-items: center;
}
@media (max-width: 1200px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 764px) {
    .popular-locations-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 446px) {
    .popular-locations-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.popular-locations-grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 7px;
}