.whatsAppWrapper {
    background-color: var(--clr-wa);
    position: relative; 
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 0;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    margin-left: 5rem;
}

.whatsAppWrapper:hover {
    transform: scale(1.1);
}

.whatsAppWrapper:hover::after {
    content: "Need Help?";
    position: absolute;
    background-color: white;
    color: var(--clr-gray-800);
    font-size: 0.7rem;
    top: 50%;
    right: 100%; 
    transform: translate(-10px, -50%); 
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-weight: bold;
    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}