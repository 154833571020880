.titlesContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.imageContainer {
    width: 100%;
    height: 60vh;
    position: relative;
}

