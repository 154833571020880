
.mainBlogContainer {
    position: relative;
    z-index: 10;
    margin-top: -8%;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    padding: 3rem 4rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    border-radius: 1rem ;
    gap: 2rem;
    margin-bottom: 150px;
}

.loader-single-blog-page {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media (max-width: 1420px) {
    .mainBlogContainer {
        width: 80%;
    }
}


@media (max-width: 900px) {
    .mainBlogContainer {
        width: 90%;
        padding: 2rem;
    }

}
