.floorPlansSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 2rem;
}

.titleButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.titleButtonContainer a {
    color: var(--clr-purple);
}

.titleButtonContainer.images > img {
    width: 40%;
}

.createCustomDiagramContainer > img{
    width: 20%;
    cursor: pointer;
    
}

.createCustomDiagramContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    
}

.createCustomDiagramSubContainer {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid var(--clr-purple);
    position: absolute;
    bottom: 100%;
    left: 45%;
    margin-bottom: -5px;

}

.createCustomDiagramSubContainer.show {
    visibility: visible;
    opacity: 1;
}