.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    padding: calc(1rem + 2vw)  calc(0.8rem + 1vw);
    max-width: 60vw;
    min-width: 50vw;
    border-radius: 5px;
    position: absolute;
    top: 60px;
    right: -25vw;
    z-index: 1405;
    background-color: white;
    gap: 10px;
}

.container.list {
    top: 270px
}

.title{
    color: var(--clr-purple);
    font-weight: bold;
    letter-spacing: 0;
}

.checkboxContainer {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;    
    grid-gap: 1rem;
}

.checkboxItem {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.buttonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5%;
    column-gap: 1rem;
    margin-top: 1rem;
}

@media (max-width: 1300px) {
    .container {
        left: -50vw;
    }
}

@media (max-width: 1001px) {
    .container {
        left: 0;
    }
}

@media (max-width: 744px) {
    .container{
        right: 0;
        left: unset;
        width: 80vw;
    }
}

@media (max-width: 622px) {
    .container{
        right: unset;
        left: 0;
        width: 90vw;
    }
}


@media (max-width: 600px) {
    .container{
        right: 0;
        left: unset;

    }
}




@media (max-width: 559px) {
    .container{
        right: unset;
        left: 0;

    }
}

@media (max-width: 470px) {
    .container{
        right: unset;
        left: -20vw;

    }
}

@media (max-width: 436px) {
    .container{
        right: unset;
        left: -30vw;

    }
}

@media (max-width: 350px) {
    .container{
        right: unset;
        left: -40vw;

    }
}

@media (max-width: 304px) {
    .container{
        right: unset;
        left: 0;

    }
}

@media (max-width: 580px) {

    .checkboxContainer {
        grid-template-columns: repeat(2, 1fr);
    }

    .buttonsContainer {
        justify-content: flex-start;
        margin: 0;
        flex-wrap: wrap;
        gap: 5px;
    }

}

@media (max-width: 400px) {


    .checkboxContainer {
        grid-template-columns: repeat(1, 1fr);
    }

}
