.longDescription p:first-of-type::first-letter {
    float: left;
    font-size: 3em;
    line-height: 1;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    padding-right: 0.1em;
}

.longDescription p {
    word-spacing: 2px;
}
.longDescription > button {
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--clr-purple);
    font-size: 1.2rem;
    text-decoration: underline;
    cursor: pointer;
}