.stickyNavbar {
    position: sticky;
    top: 0;
    z-index: 1200;
    background-color: white;
    width: 100vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem 0;
}

.stickyNavbar div {
    cursor: pointer;
    font-weight: 600;
}