.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

.grid {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 1024px) {
    .grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
}