
.customized-carousel-container .carousel .slider-wrapper{
    max-width: 100% !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    padding: 2rem 0;
     min-width: 300px;

}

.customized-carousel-container .carousel.carousel-slider {
    overflow: visible;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.customized-carousel-container .carousel .thumb {
    display: none;
}

.customized-carousel-container .carousel .control-dots .dot {
    border-radius: 30% !important;
    height: 5px !important;
    width: 12px !important;
    box-shadow: none !important;
    background: var(--clr-gray-300) !important;
}

.customized-carousel-container .carousel .control-dots .selected {
    background: var(--clr-yellow) !important;;
    box-shadow: none !important;;
}

.customized-carousel-container .slide-bg {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    row-gap: 1rem;
    /* min-width: 700px; */
}

.customized-carousel-container .slide-bg div {
    flex: 1;
}

.customized-carousel-container .carousel-status {
    display: none;
}

.carousel-title {
    font-size: 2rem;
    color: rgb(59, 59, 59);
    font-style: italic;
}

.carousel-text {
    color: var(--clr-gray-300);
}

.carousel-subtitle {
    font-size: 0.8rem;
    color:var(--clr-gray-300);
}

.carousel-name {
    color: var(--clr-gray-500);
    font-weight: bold;
}