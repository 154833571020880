.rowMainContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.rowSubContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.inputStyle {
    border: none;
    outline: none;
    max-width: 30px;
    text-align: center;
}

.inputStyle:focus {
    border: none;
    outline: none;
}