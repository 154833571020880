.firstButton {
    border: 1px solid var(--clr-purple);
    border-radius: 0.3rem 0 0 0;
    margin-right: 0.2rem;
    font-size: 0.7rem;
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    background-color: white;
    cursor: pointer;
}

.secondButton {
    composes: firstButton;
    border-radius: 0 0 0.3rem 0;
}

.clickedButton {
    composes: firstButton;
    background-color: var(--clr-purple);
    color: white;
    border: 1px solid var(--clr-purple);
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.firstButton:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}
