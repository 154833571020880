.footer {
    display: flex;
    flex-wrap: wrap;
    width: 82vw;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    padding: 3rem 0;
    row-gap: 10rem;
}
.upperFooter {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 6rem;
    width: 100%;
}
.footerCol {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.footerCol > div {
    font-size: 0.9rem;
}

.footerTitle{
    font-weight: 600;
    font-size: 1rem;
}

.footerPhone {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.footerBottomContainer {
    display: flex;
    width: 82vw;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;
}

.footerBottomIconsContainer {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
}

.privacy {
    color: #80909e;
}
.hoverItem {
    transition: transform 0.3s;

}


.hoverItem:hover {
    transform: translateX(5px);
}

@media (max-width: 1256px) {
    .upperFooter {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 967px) {
    .footer {
        row-gap: 5rem;
    }
    .upperFooter {
        grid-template-columns: repeat(3, 1fr);
    }
    
}

@media (max-width: 767px) {
    .upperFooter {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3rem;
    }
}

@media (max-width: 447px) {
    .upperFooter {
        grid-template-columns: repeat(1, 1fr);
    }
}