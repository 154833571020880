.leftContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}

.rightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto 1fr auto auto;
    width: 100%;
    column-gap: 1rem;
    row-gap: 2rem;
    justify-content: center;
    align-items: flex-start;
}

.form .dateRangePicker {
    grid-column: 1 / span 2;
}

.form .purposeOfEvent {
    grid-column: 1 / span 2;
}

.flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.marginTop {
    margin-top: 9px;
}

@media (max-width: 1224px) {
    .leftContainer,
    .rightContainer {
        flex: none;
        width: 100%;
        align-items: center;
    }

    .form {
        grid-template-columns: 1fr;
    }
}
