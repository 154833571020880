@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}



.mainSection {
    display: grid;
    grid-template-columns: 10% 40% 50%;
}

.responsiveContainer {
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; /* This will push the children to the bottom */
    height: 100%; /* Set a height if not already set. Can adjust as necessary */

}

.responsiveContainer .carousel-root{
    width: 100vw;
    position: relative;

}

.sliderDots.centerAside {
    display: flex;
    align-items: center;
}

.sliderDots .carousel .control-dots .dot {
    width: 20px;
    background: rgb(211,214,232);
    box-shadow: none;
    border-radius: 35%;
}

.sliderDots .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background:white;
}

.sliderDots .carousel .control-dots {

}

.sliderVenueTextContainer {
    color: white;
    width: max-content;
    position: absolute;
    z-index: 100;
    /*left: 85%;*/
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    bottom: 3rem;
}

.sliderVenueTextContainer.mobile {
    top: calc(1rem + 0.9vw);
    left: 1rem;
    bottom: unset;
    justify-content: flex-start;
    align-items: flex-start;
}

.sliderVenueName {
    z-index: 100;
    transform: translateX(-50%);
    letter-spacing: 1px;
    font-family: 'Playfair Display', serif;
    font-size: calc(1rem + 0.9vw);
    text-shadow: 2px 3px 5px rgba(0,0,0,0.5);
    font-weight: 500;
}

.sliderVenueName.location {
    font-size: calc(0.5rem + 0.5vw);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;

}

@media (max-width: 1120px) {
    .sliderVenueTextContainer {
        left: 1rem;
    }
}

@media (max-width: 1250px){
    .sliderVenueName {
        transform: none;
    }
}

.mainSectionTitle {
    position: absolute;
    z-index: 10;
    top: 0;
    width: 100%;
    margin: auto;
    color: var(--clr-purple);
    font-size: 3rem;
    font-weight: 500;
}



.shapeImg {
    position: absolute;
    height: 500px;
    width: 100px;
}
.mainSectionImageContainer {
    position: relative;
}

.mainSectionImageContainer img {
    max-height: 60vh;
    object-fit: cover;
}

.mainSectionLeftPart {
    margin-left: 1rem;
    margin-top: 1rem;
}

.mainSectionMain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 3rem;
}

.mainSectionButton {
    min-width: 100px;
    max-width: 400px;
    background-color: rgba(73, 85, 157, 0.9);
    color: white;
    border-radius: 0.5rem 0 0.5rem 0;
    border-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    bottom: 40px;
    align-self: center;
    padding: 10px 20px;
    box-shadow: rgba(100, 100, 111, 0.8) 0 7px 29px 0;
}

.mainSectionModalCard {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainSectionModalCardContainer {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: calc(0.5rem + 0.9vw);
    top: 0;
    left: 0;
    z-index: 1000;
    gap: calc(1rem + 0.5vw);
    backdrop-filter: blur(3px);
    position: fixed;
    animation: fadeOut 0.3s forwards;

}
.mainSectionModalCardContainer.open {
    animation: fadeIn 0.3s forwards;
}
