.leaflet-container-preview-page .leaflet-container {
    position: relative;
    height: 400px;
    width: 70vw;
}

.list-page {
    position: relative;
width: 100%;
}

.list-page .leaflet-container {
    position: sticky;
    top: 10px;
    right: 0;
    min-height: 50vh;
    height: 100%;
    max-height: 98vh;
    width: 100%;
    margin: 1rem 0;
}

.list-page .leaflet-top.leaflet-left {
    position: relative;
    z-index: 400;
}

@media (max-width: 700px) {
    .list-page .leaflet-container {
        display: none;
        width: 0;
        height: 0;
    }
    .list-page {
        display: none;
    }
}