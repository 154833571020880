.buttonGroup {
    max-width: 700px;
    display: flex;
}

.buttonGroupTable {
    border-collapse: collapse;
}

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border: 1px solid var(--clr-gray-250);
    background-color: white;
    cursor: pointer;
    padding: 6px 14px;
    transition: background-color 0.2s ease;
}

.btn.list {
    margin-right: 3px;
    border-radius: 0.3rem;
}

.btn.mobile {
    /*background-color: var(--clr-purple);*/
    /*color: white;*/
    border-right: 1px solid var(--clr-gray-250);
}

.btn.mobile.active {
    background-color: var(--clr-purple);
    color: white;
}

.btn.first {
    border-top-left-radius: 0.5rem;
}

.btn.last {
    border-bottom-right-radius: 0.5rem;
}

.btn.active {
    font-weight: 500;
    background-color: var(--clr-gray-200);
}

.btn.list.active {
    color: white;
    background-color:var(--clr-purple) ;
}

