.dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dropdownBtn {
  border: 1px solid var(--clr-mui-border);
  padding: 10px;
  border-radius: 0.4rem 0 0.4rem 0;
  color: var(--clr-gray-500);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0s ease-in-out;
  width: 100%;
}

.dropdownBtn:focus {
  border: 1px solid var(--clr-gray-280);
}

.dropdownLabel {
  margin-bottom: 5px;
  color:var(--clr-gray-500);
}

.basic {
  min-width: 300px;
}

.dropdownBtnLabel {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  font-weight: 600;
  color: var(--clr-gray-500);
}

.numerical,
.doubleNumerical {
  min-width: 200px;
}

.numerical {
  justify-content: space-between;
}

.dropdownBtn.numerical {
  width: 100%;
  padding: 9px;
  height: 56px;
  border-radius: 0.5rem 0 0.5rem 0;
  margin-top: 8px;
}

.dropdownBtn.numerical:hover {
  border-color: black;
}

.doubleNumerical {
  justify-content: flex-end;
  gap: 0.5rem;
}

.doubleNumericalIconsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}


@media(max-width: 400px) {
  .basic {
    min-width: 200px;
  }
}