
.smallerImageContainer {
    position: absolute;
    bottom: -120px;
    right: -120px;
    border-radius: 50%;

}

.smallerImageContainer img {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

}
@media (max-width: 900px) {

    .smallerImageContainer {
        display: none;
    }

}
