.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 24px;
}

.container.gray {
    background-color: var(--clr-gray-200);
}

.icon {
    width: 60%;
    height: 60%;
}

.item.item1, .item.item3 {
    flex: 1;
}

.item.item2 {
    flex: 10;
}
