.paymentDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

.title {
    font-size: 2rem;
    color: black;
}

.creditCardIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.grayText {
    color: var(--clr-gray-280);
}

.inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.pinkText {
    color: var(--clr-pink);
    font-weight: 500;
}