.grid {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.grid > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--clr-gray-300);
    padding: 10px 0;
}

.gridRow > div:not(:first-child) {
    font-weight: 600;
}

.gridRow > div:first-child {
    color: var(--clr-purple);
}

.grid > div:last-child {
    border-bottom: 1px solid var(--clr-gray-300);
}

.grid > div > div:first-child,
.grid > div > div:nth-child(2),
.grid > div > div:nth-child(3) {
  width: 33%;
}

.grid > div > svg {
  width: 32px;
}

.gridCards {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1rem;
}

@media(max-width: 1124px) {
    .gridCards {
        grid-template-columns: repeat(2,1fr);
    }
}

@media(max-width: 770px) {
    .gridCards {
        grid-template-columns: repeat(1,1fr);
    }
}
