.newsletterContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    padding:  2rem 3rem;
}
.newsletterForm {
    display: flex;
    flex-direction: row;
    gap: 4rem;
}

.formSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

@media (max-width: 900px) {
    .newsletterForm {
        flex-direction: column;
        gap: 2rem;
    }

    .formSection {
        gap: 1.5rem;
    }
}
