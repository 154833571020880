.iconContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.gray {
    color: var(--clr-gray-500);
}

.iconContainer img {
    width: 20%;
    height: 20%;
    object-fit: contain;
    cursor: pointer;
}