:root {
    --clr-yellow: #ffd600;
    --clr-pink: #e0006c;
    --clr-purple: #49559d;
    --clr-wa: #25d366;
    --clr-gray-100: #eceef5; /*dropdown hover*/
    --clr-gray-200: #f5f6f7; /*venues btn bg*/
    --clr-gray-250: #e9e9e9;
    --clr-gray-280: #b7bcd8; /*dropdown focus bgc*/
    --clr-gray-300: #c1c8d2; /*dropdown location icon*/
    --clr-gray-500: #707070; /*leaf icon clr*/
    --clr-gray-800:#454e56; /*dropdown text color*/
    --clr-blue-link: #95b4fb;
    --clr-black: #000000;
    --clr-mui-border: #c4c4c4;
    --toastify-color-progress-light: #e0006c !important;
}

