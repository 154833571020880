.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 70%;
    height: 100%;
}

.container.grid {
    width: 100%;

}

.header {
    background-color: var(--clr-pink);
    color: white;
    border-radius: 1rem 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    font-weight: bold;
    font-size: 1.3rem;
    width: 100%;
}

.header.grid {
    font-size: 1.1rem;
}

.centered {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inquiriesList {
    width: 100%;
    border: 1px solid var(--clr-pink);
    border-radius: 0 0 1rem 0;
    padding: 0.5rem 1rem;
    list-style-type: none;
    flex-grow: 1000;
}

.inquiriesList > li, .sublist > li {
    padding: 5px 0;
}

.sublist {
    list-style-type: none;
}

.keyText {
    font-weight: 500;

}

@media (max-width: 1200px) {
    .container {
        width: 100%;
    }
}