.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.titleSubContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 1rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
    grid-template-rows: repeat(3, auto);
    gap: 1rem;
    width: 100%;
    min-height: 50vh;
}

.gridItem {
    text-align: center;
    position: relative;
}

.gridItem > img {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 1rem 0 1rem 0;
}

.spanCol1 {
    position: relative;
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
}

.spanCol2 {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
}

.spanCol3 {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    position: relative;
}

.stickyNavbar {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem 0;
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
}

.twoGalleriesGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

}

.twoGalleriesGrid > * {
    flex: 1;
}


@media (max-width: 768px) {
    .titleContainer {
        gap: 1rem;
    }
    .grid {
      grid-template-columns: 1fr;
    }
    .twoGalleriesGrid {
        flex-direction: column;
    }
    .spanCol1 {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
    
    .spanCol2 {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
    }
    
    .spanCol3 {
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
    }
}