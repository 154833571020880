.image-gallery {
    width: 100%;
    height: 100vh;
}

.image-gallery-content.bottom {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.image-gallery-slide-wrapper.bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-slide {
    height: 70vh;
    max-width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    row-gap: 3rem;
}

.imageGalleryCloseBackContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BackGalleryButton {
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 0.5rem 0 0.5rem 0;
    background-color: transparent;
    color: white;
    cursor: pointer;
}

.custom-slide img {
    height: 60%;
    max-width: 100%;
    object-fit: contain;
}

.arrow-container {
    width: 40px;
    height: 40px;
    background-color: rgba(62,67,73, 0.7);
    z-index: 3000;
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.image-gallery-icon.image-gallery-left-nav {
    color: white;
    background-color: rgba(128, 128, 128, 0.541);
    border-radius: 50%;
    font-size: 1rem;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.image-gallery-svg {
    font-size: 1rem;
}

.custom-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
  }
  
  .custom-left-arrow {
    left: -100px;
  }
  
  .custom-right-arrow {
    right: -50px;
  }

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    touch-action: pan-y;
    width: 100%;
}

.image-gallery-thumbnails {
    width: 100%;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {

    justify-content: center;
    transform: translate3d(0px, 0px, 0px);
    transition: all 450ms ease-out 0s;
    width: 100%;
    display: flex;
    gap: calc(0.5rem + 1vw);
    align-items: center;

}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover{
    outline: none;
    border: 4px solid white !important;
    border-radius: 1rem 0 1rem 0; 
    cursor: pointer;
    height: 88px;
}

.image-gallery-thumbnail, .image-gallery-thumbnail-inner,.image-gallery-thumbnail-image {
    border-radius: 0.8rem 0 0.8rem 0;
    height: 82px;
    object-fit: cover;
}

.custom-slide, .custom-slide img {
    border-radius: 1rem 0 1rem 0;
}

.react-gallery-title {
    font-size: 2rem;
    color: white;
}

.react-gallery-subtitle {
    color: white;
    font-size: 1.2rem;
}

@media (max-width: 1100px) {
    .custom-slide {
        height: 60vh;
        width: 80vw;
    }

    .custom-arrow {
        font-size: 20px;
    }

    .custom-slide img {
        height: auto;
    }

    .arrow-container {
        width: 35px;
        height: 35px;
    }

    .react-gallery-title {
        font-size: 1.5rem;
    }
}

@media (max-width: 850px) {
    .custom-left-arrow {
        left: 10px;
    }

    .custom-right-arrow {
        right: 50px;
    }
}