@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


.blogContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.blogContainer.smaller {
    max-width: 85%;
}

.blogTitle {
    font-weight: 500;
    font-size: 1.5rem;
}

.upperContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 0.8rem;
}


.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    width: 100%;
    margin: 2rem auto;

}


.gridContainer > * {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    flex: 0 0 calc(100% / 3);
}

.listContainer {

}

@media (max-width: 900px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .gridContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}
