.dropdown {
    position: absolute;
    top: 60px;
    left: 0;
    max-width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    z-index: 10;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    padding: 1rem;
    color: var(--clr-purple);
    font-weight: 600;
    font-size: 1.1rem;
    cursor: auto;
}

.subcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: #454e56;
    font-weight: 400;
    font-size: 1rem;

}

.row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.inputNum {
    outline: none;
    border: none;
    width: 1rem;
}