.summaryContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    border: 1px solid #eceef5;
    width: 100%;
}

.summaryContainer > * {
    padding: 1rem;
}

.freeServiceSection {
    background-color: #eceef5;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.scrollableShortlist {
    height: 150px;
    overflow-y: scroll;
}

.freeServiceSubsection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

@media(max-width: 900px) {
    .summaryContainer  {
       width: 100%;
    }
}
