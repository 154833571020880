.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  padding: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.container.noShadow {
  box-shadow: none;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.iconsContainer {
  display: flex;
  margin-right: 35%;
}

.selectContainer {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  gap: 1rem;
}

@media (min-width: 1500px) {
  .selectContainer {
    justify-content: space-between;
  }
}

.selectContainer > div:nth-child(1),
.selectContainer > div:nth-child(2) {
  flex: 2;
}

.selectContainer > div:nth-child(n + 3) {
  flex: 1;
}

.upperContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.searchButton {
  background-color: var(--clr-pink);
  padding: 10px;
  border-radius: 0.5rem 0 0.5rem 0;
  border: 1px solid var(--clr-pink);
  color: white;
  min-height: 56px;
  cursor: pointer;
  margin-top: 8px;
  width: 200px;
  max-width: 600px;
  font-weight: 500;
  letter-spacing: 1px;
}

.searchButton:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

@media (max-width:1200px){
  .iconsContainer {
    margin-right: 0;
  }
}


@media (max-width: 740px) {
  .upperContainer {
    justify-content: center;
  }
  .iconsContainer {
    margin-right: 0;
  }
  .selectContainer {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .upperContainer{
    flex-direction: column;
  }
  .buttonsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width: 400px) {
  .buttonsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}