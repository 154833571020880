.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr; /* This creates two columns of equal width */
    gap: 1rem; /* Adjust this value for spacing between grid items */
}


.gridContainer > :first-child {
    grid-column: span 2; /* This makes the first child (RowRadioButtonsGroup) span two columns */
}

.noTitleForm {
    display: grid;
    grid-template-columns: 1fr 1fr; /* This creates two columns of equal width */
    gap: 1rem; /* Adjust this value for spacing between grid items */
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: 2rem;
    padding: 1rem 3rem;
}

.contactDetailsFrom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    padding: 3rem;
    margin-bottom: 4rem;
}

.subtitle {
    font-size: 1.3rem;
    font-weight: 500;
}

.responsesContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.sharing {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 700px;
}