.container {
    overflow-x: auto;
}
.container table {
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    border: 1px solid #ddd;
    border-radius: 1rem 0 1rem 0;
}

.container th,
.container td {
    text-align: left;
    padding: 8px;
    border-right: 1px solid #ddd;
    position: relative;
    color: black;
    font-weight: 400;
}

.headerCell {
    background-color: #f0f5ff;
    width: 150px;
}

.container th > img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 1rem 0 1rem 0;
}

/*.dynamicDataCell {*/
/*    width: 300px;*/
/*}*/

.imageCell {
    padding: 0 !important;
}

.dynamicDataCell.venueName {
    font-weight: 600;
    color: var(--clr-purple);
}

.dynamicDataCell.buttonCell {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}