.shortlistedCard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #eceef5;
    padding: 0;
    border-radius: 0.5rem 0 0 0;
    margin: 0 1rem 1.5rem;
    gap: 10px;
}

.image {
    width: 20%;
    height: 15%;
    object-fit: contain;
    border-radius: 0.5rem 0 0.5rem 0;
}