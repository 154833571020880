.imageContainer {
    width: 100%;
    height: 60vh;
    min-height: 300px;
    max-height: 600px;
    overflow: hidden;
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
}
