.dropdownContainer{
    display: flex;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    padding: 2rem;
    width: 1000px;
    border-radius: 5px;
    position: absolute;
    top: 80px;
    z-index: 6;
    left: -500px;
}

.dropdownContainer.closed {
    display: none;
}

.buttons {
    display: flex;
    column-gap: 0.6rem;
    justify-content: flex-end;
}