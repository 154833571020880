.wrapper {
    display: flex;
    flex-direction: column;
}

.wrapper.home {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    gap: 2rem;
}

.wrapper.list {
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 3rem;
    padding: 2rem;

}

.wrapper.getQuote {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 1rem;
    margin: 4rem auto;
    position: relative;
}

.wrapper.blogs {
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 2rem;
    margin: 2rem auto;
}

.wrapper.blog {
    align-items: center;
}

.wrapper.stayForm {
    position: relative;
    width: 90%;
    margin: 0 auto 2rem;
    gap: 2rem;
}

.wrapper.previewPage {
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    gap: 2rem;
    height: 100%;
}

.wrapper.previewPage2 {
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    gap: 1rem;
}

.wrapper.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 85%;
    margin: 4rem auto;
    grid-gap: 2rem;
}



@media (max-width: 1224px) {
    .wrapper.getQuote {
        width: 90%;
        flex-direction: column;
    }

    /*.wrapper.list {*/
    /*    width: 90vw;*/
    /*}*/
}

@media (max-width: 1100px) {
    .wrapper.list {
        flex-direction: column;
    }
}



@media (max-width: 1200px) {
    .wrapper.list {
        width: 95vw;
        margin: 0 auto;
        padding: 0;
    }

    .wrapper.grid {
        grid-template-columns: 1fr;
        width: 95%;
        margin: 2rem auto;
    }
}

@media (max-width: 600px) {
    .wrapper.list {
        width: 100vw;
        padding: 1rem;
    }
}

@media (max-width: 400px) {
    .wrapper.list {
        padding: 0.5rem;
    }

    .wrapper.previewPage {
        width: 95vw;
    }

}
