.dontHaveAccount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.rememberMe {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.container {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    padding: 2rem 0;
}

.formContainer {
    width: 100%;
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

@media (max-width: 1024px) {
    .rememberMe {
        flex-direction: column;
        align-items: flex-start;
    }

    .dontHaveAccount {
        flex-direction: column;
        align-items: flex-start;
    }

    .container {
        width: 80%;
    }

    .formContainer {
        margin: 1rem;
    }
}